<template>
  <section class="section-position" :class="{
      'section-default': defaultClass,
      'default-gup': defaultGup,
      'section-overflow-reverts': isOverflowReverts
    }">
    <base-background>
      <slot name="section-background"></slot>
    </base-background>
    <div class="content">
      <h3 v-show="title">{{title}}</h3>
      <slot></slot>
    </div>

  </section>
</template>

<script>
import BaseBackground from '@/components/base/background/BaseBackground'

export default {
  name: 'BaseSection',
  components: { BaseBackground },
  props: {
    defaultClass: {
      type: Boolean,
      default: true
    },
    defaultGup: {
      type: Boolean,
      default: true
    },
    isOverflowReverts: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
