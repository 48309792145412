<template>
  <inline-svg :src="src"/>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'SvgItem',
  props: ['src'],
  components: {
    InlineSvg
  }
}
</script>

<style scoped>

</style>
