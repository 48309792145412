<template>
<div class="persona-content">
  <div class="persona-content__introduction introduction">
    <div class="logo introduction__logo"
      v-show="getLogo"
    >
      <img :src="getLogo" :alt="getFullIntroduce"/>
    </div>
    <div class="introduce__content">
      <span class="introduce-content__personal">{{getFullIntroduce}}</span>
      <span class="introduce-content__position"
        v-show="getPosition">
        {{getPosition}}
      </span>
      <div class="introduce-content__hint hint" v-if="isFooterIntroduce">
        {{$tc('PORTAL.SYSTEM.PLURALISATION.PERSONA-YEARS', getAge)}}, {{getMemberCity}}
      </div>
    </div>
  </div>
  <div class="persona-content__contacts">
    <base-contact-list :contacts="getContacts"/>
  </div>
</div>
</template>

<script>
import BaseContactList from '@/components/base/contact/BaseContactList'
export default {
  name: 'PersonaContent',
  components: { BaseContactList },
  props: {
    member: {
      type: [Object],
      default: () => {
        return {
          surname: undefined,
          firstname: undefined,
          middlename: undefined,
          position: undefined,
          logo: undefined,
          age: undefined,
          contacts: []
        }
      }
    }
  },
  computed: {
    getPosition () {
      return this.member?.position || this.member?.work?.position
    },
    getLogo () {
      return this.member?.logo?.url
    },
    getFullIntroduce () {
      return `${this.member.surname} ${this.member.firstname}`
    },
    getContacts () {
      return this.member?.contacts || []
    },
    getAge () {
      return this.member?.age
    },
    getMemberCity () {
      return this.member?.address?.city
    },
    isFooterIntroduce () {
      return this.getAge || this.getMemberCity
    }
  }
}
</script>
