<template>
    <div class="bg">
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'BaseBackground'
}
</script>
