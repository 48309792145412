<template>
  <div class="bg__square"
      :class="{'bg__square--green': isGreen}"
  >
    <svg-item src="/assets/img/square-circles.svg"/>
  </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'
export default {
  name: 'BaseBackgroundItemSquare',
  components: { SvgItem },
  props: {
    isGreen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
