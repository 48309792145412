<template>
    <div class="contact-list">
        <base-contact-item v-for="contact in contacts"
                           :key="contact.id"
                           :is-icon="isIcon"
                           :contact="contact"/>
    </div>
</template>

<script>
import BaseContactItem from '@/components/base/contact/BaseContactItem'
export default {
  name: 'BaseContactList',
  components: { BaseContactItem },
  props: {
    contacts: {
      type: Array,
      default: () => {
        return []
      }
    },
    isIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>
