<template>
  <article class="card"
    :class="{
    'card--pointed': pointed,
    'card--vertical': isVertical,
    'card--no-shadow': isNoShadow
  }"
  >
  <div class="card__title">
    <slot name="card-title"></slot>
  </div>
    <slot name="card-logo"></slot>
    <div class="card__content">
      <slot></slot>
    </div>
    <slot name="card-footer"></slot>
  </article>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    pointed: {
      type: Boolean,
      default: false
    },
    clicked: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    },
    isNoShadow: {
      type: Boolean,
      default: false
    }
  }
}
</script>
