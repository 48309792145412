<template>
  <div class="bg__oval"
       :class="{'bg__oval--green': isGreen,
       'bg__oval--shine-green': isShineGreen,
       'bg__oval--light-blue': isLightBlue
  }">
    <svg-item src="/assets/img/oval.svg"/>
  </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'

export default {
  name: 'BaseBackgroundItemOval',
  props: {
    isGreen: {
      type: Boolean,
      default: false
    },
    isShineGreen: {
      type: Boolean,
      default: false
    },
    isLightBlue: {
      type: Boolean,
      default: false
    }
  },
  components: { SvgItem }
}
</script>

<style scoped>

</style>
