<template>
  <div class="bg__triangle"
    :class="{'bg__triangle--green': isGreen, 'bg__triangle--shine-blue': isShineBlue}"
  >
    <svg-item src="/assets/img/triangle.svg"/>
  </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'
export default {
  name: 'BaseBackgroundItemTriangle',
  components: { SvgItem },
  props: {
    isGreen: {
      type: Boolean,
      default: false
    },
    isShineBlue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
