<template>
  <div class="bg">
    <base-background-swiper
      v-show="!loading">
      <base-background-swiper-slide
        v-for="item in list"
        :key="item.id"
        :url="item.url"/>
    </base-background-swiper>
    <div class="bg--overlay"></div>
    <slot></slot>
  </div>
</template>

<script>
import BaseBackgroundSwiper from '@/components/base/backgroundSwiper/BaseBackgroundSwiper'
import BaseBackgroundSwiperSlide from '@/components/base/backgroundSwiper/BaseBackgroundSwiperSlide'

export default {
  name: 'SectionMainBackgrounds',
  components: {
    BaseBackgroundSwiperSlide,
    BaseBackgroundSwiper
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
  // async mounted () {
  //   await this.getBgItems()
  // },
  // computed: {
  //   ...mapGetters(STORE_PATH_BACKGROUNDS_MAIN, [
  //     COMMON_LOADER_GETTER_IS_LOADING,
  //     BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST]),
  //   getBgList () {
  //     return this[BACKGROUNDS_MAIN_GETTER_GET_MAIN_SLIDE_BG_LIST]
  //   },
  //   isLoading () {
  //     return this[COMMON_LOADER_GETTER_IS_LOADING]
  //   }
  // },
  // methods: {
  //   ...mapActions(STORE_PATH_BACKGROUNDS_MAIN, [BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG]),
  //   async getBgItems () {
  //     await this[BACKGROUNDS_MAIN_ACTION_FETCH_MAIN_SLIDE_BG]()
  //   }
  // }
}
</script>
