<template>
  <swiper-slide :style="{ 'background-image': `url(${url})`}">
  </swiper-slide>
</template>

<script>
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import { Swiper as SwiperClass } from 'swiper/swiper.esm.js'
const { SwiperSlide } = getAwesomeSwiper(SwiperClass)
export default {
  name: 'BaseBackgroundSwiperSlide',
  components: { SwiperSlide },
  props: {
    url: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
