<template>
  <div class="bg__blot" :class="{'bg__blot--light-blue': isLightBlue}">
    <svg-item src="/assets/img/blot.svg"/>
  </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'
export default {
  name: 'BaseBackgroundItemBlot',
  components: { SvgItem },
  props: {
    isLightBlue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
