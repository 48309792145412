<template>
<div class="icon-text">
  <base-icon :icon="icon"/>
  <span class="icon-text__value"><slot></slot></span>
</div>
</template>

<script>
import BaseIcon from '@/components/base/icon/BaseIcon'
export default {
  name: 'BaseIconText',
  components: { BaseIcon },
  props: {
    icon: {
      required: true,
      type: [Number, String, undefined]
    }
  }
}
</script>
