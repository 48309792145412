<template>
    <swiper
    class="bg-swiper"
    :ref="$options.name"
    :options="getOptions"
    >
      <slot></slot>
    </swiper>
</template>

<script>
// import SwiperCore, { EffectFade, Autoplay } from 'swiper'
// import { Swiper as SwiperClass, Autoplay, EffectFade } from 'swiper/swiper.esm.js'
import { Swiper as SwiperClass, EffectFade, Autoplay } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// import 'swiper/swiper-bundle.min.css'
import 'swiper/components/effect-fade/effect-fade.scss'
// SwiperCore.use([Autoplay, EffectFade])
SwiperClass.use([Autoplay, EffectFade])
const { Swiper } = getAwesomeSwiper(SwiperClass)
export default {
  name: 'BaseBackgroundSwiper',
  components: {
    Swiper
  },
  data () {
    return {
      swiperOptions: {
        autoplay: {
          delay: 15000
        },
        speed: 4000,
        fadeEffect: {
          crossFade: true
        },
        effect: 'fade',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0

      }
    }
  },
  computed: {
    getOptions () {
      return this.swiperOptions
    }
  }

}
</script>

<style scoped>

</style>
