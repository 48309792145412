<template>
  <div class="card-list"
       :class="{'card-list--column': isColumn, 'card-list--rows': !isColumn}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseCardList',
  props: {
    isColumn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
