<template>
    <div class="bg__astro">
      <svg-item src="/assets/img/astro.svg"/>
    </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'
export default {
  name: 'BaseBackgroundItemAstro',
  components: { SvgItem }
}
</script>
