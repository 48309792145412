<template>
  <div class="bg__ellipse"
       :class="{'bg__ellipse--green': isGreen}"
  >
    <svg-item src="/assets/img/ellipse.svg"/>
  </div>
</template>

<script>
import SvgItem from '@/components/misc/SvgItem'

export default {
  name: 'BaseBackgroundItemEllipse',
  components: { SvgItem },
  props: {
    isGreen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
