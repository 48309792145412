<template>
<div class="contact">
  <base-button
  is-link
  target="_blank"
  :href="getLink"
  :icon="$t(getIcon)"
  >
    {{getValue}}
  </base-button>
</div>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import {
  C_CONTACTS_TYPE_MOBILE_HOME,
  C_CONTACTS_TYPE_MOBILE_OFFICE,
  C_CONTACTS_TYPE_STATION_HOME,
  C_CONTACTS_TYPE_STATION_OFFICE,
  C_CONTACTS_TYPE_URL,
  C_CONTACTS_TYPE_HOME_EMAIL,
  C_CONTACTS_TYPE_FACEBOOK,
  C_CONTACTS_TYPE_OK,
  C_CONTACTS_TYPE_VK,
  C_CONTACTS_TYPE_TWITTER,
  C_CONTACTS_TYPE_INSTAGRAM,
  C_CONTACTS_TYPE_TELEGRAM,
  C_CONTACTS_TYPE_WHATSAPP,
  C_CONTACTS_TYPE_VIBER,
  C_CONTACTS_TYPE_ACTUAL_ADDRESS,
  C_CONTACTS_TYPE_OFFICE_EMAIL
} from '@/lib/constant/c.contacts'
import { phoneFormat } from '@/lib/utils/format'
export default {
  name: 'BaseContactItem',
  components: { BaseButton },
  props: {
    contact: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          type: undefined,
          value: undefined
        }
      }
    },
    isIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getType () {
      const associatingContact = {
        [C_CONTACTS_TYPE_MOBILE_HOME]: 'PORTAL.DEFAULTS.ICONS.CONTACT.PHONE',
        [C_CONTACTS_TYPE_MOBILE_OFFICE]: 'PORTAL.DEFAULTS.ICONS.CONTACT.PHONE',
        [C_CONTACTS_TYPE_STATION_HOME]: 'PORTAL.DEFAULTS.ICONS.CONTACT.PHONE',
        [C_CONTACTS_TYPE_STATION_OFFICE]: 'PORTAL.DEFAULTS.ICONS.CONTACT.PHONE',
        [C_CONTACTS_TYPE_INSTAGRAM]: 'PORTAL.DEFAULTS.ICONS.CONTACT.INSTAGRAM',
        [C_CONTACTS_TYPE_FACEBOOK]: 'PORTAL.DEFAULTS.ICONS.CONTACT.FACEBOOK',
        [C_CONTACTS_TYPE_OK]: 'PORTAL.DEFAULTS.ICONS.CONTACT.OK',
        [C_CONTACTS_TYPE_VK]: 'PORTAL.DEFAULTS.ICONS.CONTACT.VK',
        [C_CONTACTS_TYPE_TWITTER]: 'PORTAL.DEFAULTS.ICONS.CONTACT.TWITTER',
        [C_CONTACTS_TYPE_TELEGRAM]: 'PORTAL.DEFAULTS.ICONS.CONTACT.TELEGRAM',
        [C_CONTACTS_TYPE_WHATSAPP]: 'PORTAL.DEFAULTS.ICONS.CONTACT.WHATSAPP',
        [C_CONTACTS_TYPE_VIBER]: 'PORTAL.DEFAULTS.ICONS.CONTACT.VIBER',
        [C_CONTACTS_TYPE_ACTUAL_ADDRESS]: 'PORTAL.DEFAULTS.ICONS.CONTACT.ACTUAL-ADDRESS',
        [C_CONTACTS_TYPE_URL]: 'PORTAL.DEFAULTS.ICONS.CONTACT.SITE',
        [C_CONTACTS_TYPE_HOME_EMAIL]: 'PORTAL.DEFAULTS.ICONS.CONTACT.EMAIL',
        [C_CONTACTS_TYPE_OFFICE_EMAIL]: 'PORTAL.DEFAULTS.ICONS.CONTACT.EMAIL'
      }
      return associatingContact[this.contact.type.id] || 'PORTAL.DEFAULTS.ICONS.CONTACT.PHONE'
    },
    getIcon () {
      if (this.isIcon) {
        return this.getType || 'PORTAL.DEFAULTS.ICONS.CONTACT.PHONE'
      } else {
        return undefined
      }
    },
    getValue () {
      const associateMobilePhone = [
        C_CONTACTS_TYPE_MOBILE_HOME,
        C_CONTACTS_TYPE_MOBILE_OFFICE
      ]
      if (associateMobilePhone.includes(this.contact.type.id)) {
        return phoneFormat(this.contact.value)
      }
      return this.contact.value
    },
    getLink () {
      const associateList = {
        [C_CONTACTS_TYPE_MOBILE_HOME]: 'tel:',
        [C_CONTACTS_TYPE_MOBILE_OFFICE]: 'tel:',
        [C_CONTACTS_TYPE_STATION_HOME]: 'tel:',
        [C_CONTACTS_TYPE_STATION_OFFICE]: 'tel:',
        [C_CONTACTS_TYPE_HOME_EMAIL]: 'mailto:',
        [C_CONTACTS_TYPE_OFFICE_EMAIL]: 'mailto:'
      }
      return `${associateList[this.contact.type.id] || '//'}${this.contact.value}`
    }
  }
}
</script>
