<template>
  <component :is="tag"
             :to="to"
             class="button"
             @click="handleClick"
             :class="{
                 'button--bordered': bordered,
                 'button--accent': accent,
                 'button--link': isLink,
                 'button--fill': isFill,
                 'button--disable': isDisabled
              }"
  >
    <base-icon :icon="icon"
               v-if="icon"/>
    <slot>
        <span>
            {{value}}
        </span>
    </slot>
  </component>
</template>

<script>
import BaseIcon from '@/components/base/icon/BaseIcon'

export default {
  name: 'BaseButton',
  components: { BaseIcon },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    accent: {
      type: Boolean,
      default: false
    },
    isFill: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    isLink: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object],
      default: () => {
        return undefined
      }
    },
    icon: [String, Number, undefined]
  },
  computed: {
    tag () {
      if (typeof this.to !== 'undefined') {
        return 'router-link'
      }
      return 'a'
    }
  },
  methods: {
    handleClick (event) {
      if (this.isDisabled) {
        return false
      }
      this.$emit('click')
    }
  }
}
</script>
